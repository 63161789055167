<template>
  <div>
    <section class="container">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb
            class="breadcumb--item-color__scoped conteudos_tematicos_section_bc-color"
          >
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <v-col class="d-flex justify-center align-center">
          <img
            width="50px"
            src="../../assets/conteudos_ser_atingido_title_diamond.png"
            alt=""
          />
          <h3 class="ml-3" style="color: #E06919">
            Idosos
          </h3>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb image__section">
      <v-col cols="12">
        <img src="../../assets/conteudos_ser_atingido_tempos_21.png" />
      </v-col>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              title="Ainda há tempo de recomeçar?"
              titleColor="#426E51"
            >
              <template v-slot:conteudo>
                <p>
                  Uma vida longa e tranquila é, sem dúvida, um verdadeiro
                  triunfo. Se refletirmos sobre os idosos e idosas marianenses
                  que moravam em torno do Rio Gualaxo do Norte, podemos dizer
                  que, até o momento do rompimento da Barragem de Fundão,
                  possuíam essa dádiva. O desastre-crime comprometeu amplamente
                  a vida dos atingidos causando diversos impactos na saúde e
                  qualidade de vida dessas pessoas. Cumpre ressaltar que as
                  mudanças no local onde viviam, nas relações sociais e
                  atividades realizadas passaram a se manifestar de forma ainda
                  mais expressiva na vida dos idosos.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image mb-md-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Lá eu tinha uma vida... agora só Deus. Meu terreno, as coisas que
            eu mexia, planta, perdi tudo. Na realidade, eu tinha meus horários,
            minhas coisas todas organizadas e até hoje nada foi restabelecido.”
            <br /><strong>Bento Rodrigues, masculino, 73 anos.</strong>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col cols="12" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  O relato acima exemplifica os diversos depoimentos feitos
                  pelos idosos durante o processo de cadastramento. Os atingidos
                  e atingidas, em sua maioria, plantavam e colhiam seus próprios
                  alimentos, tinham um modo de vida marcado pelos aspectos
                  rurais. Entretanto, após o desastre-crime, muitos deles foram
                  forçados a migrar para a região urbana de Mariana, modificando
                  completamente os modos de vida tradicionais. É importante
                  ressaltar que tais mudanças estão intimamente associadas à
                  perda da qualidade de vida. Neste caso, o termo qualidade de
                  vida não se refere somente ao bem estar físico e psicológico
                  dos atingidos e atingidas, mas também às condições ambientais
                  que se relacionam, por exemplo, com a moradia, os costumes, a
                  cultura e o contexto local (NERI, 2003).
                </p>
                <p>
                  Após o rompimento, os idosos e idosas foram privados de
                  realizar suas atividades cotidianas, como os plantios, manejo
                  dos animais, comparecimento às festividades, visitas a
                  parentes e amigos e encontros nas comunidades.
                </p>
                <p>
                  Entre os principais relatos analisados nas
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'sobre_tomadas_de_termo' }"
                    >Tomadas de Termo</router-link
                  >, percebemos que os idosos e idosas atingidos manifestam
                  ansiedade e angústia com relação às memórias de uma velhice
                  que um dia já foi tranquila e incerteza quanto ao recebimento
                  de indenizações.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="7"
          class="d-flex flex-column border__image3"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Eu planejava continuar morando na comunidade, manter meu modo de
            vida. Todo ano gostava de plantar, cuidar da roça… Tinha planos de
            melhorar a casa, comprar um alambique, um carro, mas tudo foi
            interrompido depois do rompimento da barragem. Sinto muita falta da
            comunidade e tenho dúvidas que a companhia faça a reconstrução das
            casas, pelo menos para retomar a vida que tinha antes do
            rompimento.”
            <br /><strong>Paracatu de Cima, masculino, 63 anos.</strong>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Atualmente, as perspectivas de um envelhecimento digno aos
                atingidos e atingidas pelo rompimento ainda são limitadas diante
                do processo de
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{
                    name: 'conteudos_tematicos_casas_desterritorializacao',
                  }"
                  >desterritorialização</router-link
                >
                em que vivem.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <Card
            borderColor="#426E51"
            title="O modo de viver dos idosos"
            titleColor="#426E51"
          >
            <template v-slot:conteudo>
              <p>
                O processo de desterritorialização vivido pelos atingidos e
                atingidas implicou em um deslocamento forçado de 85% das
                famílias cadastradas pela Cáritas-MG, dentre essas famílias que
                migraram, 75% passaram a residir na área urbana de Mariana. Além
                de terem perdido suas residências, seus pertences, lhes foi
                tirado o vínculo afetivo com o lugar, a territorialidade
                (CLAVAL, 1973).
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row
        class="d-flex justify-center align-center justify-md-start align-md-start"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image mb-md-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Nunca pensei que pudesse sair de Bento, nunca imaginei sair de lá,
            não me imaginava fora de lá.”
            <br /><strong>Bento Rodrigues, feminino, 78 anos.</strong>
          </p>
        </v-col>
      </v-row>

      <v-row
        class="d-flex flex-md-row-reverse justify-center align-center justify-md-start align-md-start"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex flex-column border__image2 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Na parte da manhã eu arrumava a casa e descia para a chácara. Lá eu
            tratava das criações, mexia nas hortas [...]. Depois do almoço
            chegavam pessoas para comprar as coisas. Então, eu ficava mais pelas
            vendas. Depois quando fossem embora, aí eu ia descansar. Atualmente
            tudo mudou, eu não durmo, é muito choro… Até hoje as casas que eles
            poderiam entregar às pessoas, eles não entregaram. Então hoje, o dia
            a dia tá complicado.”
            <br /><strong>Bento Rodrigues, feminino, 68 anos.</strong>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Um dia costumeiro da comunidade de Bento Rodrigues é relatado
                pela idosa como algo que está distante da sua realidade atual.
                Os cultivos em quintais e manejo de animais, por exemplo,
                aparecem como atos rotineiros retirados de seu cotidiano. Além
                de serem uma importante fonte de renda para as famílias rurais,
                vale ressaltar que era vendido apenas o excedente, sendo as
                roças cultivadas sobretudo para o autoconsumo, garantindo a
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_cultura_alimentar' }"
                  >segurança alimentar</router-link
                >
                e nutricional dos muitos idosos.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <img src="../../assets/conteudos_ser_atingido_tempos_22.png" />
          <p style="font-size: 0.6em; text-align: center;">
            Foto: Tainara Torres, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <img src="../../assets/conteudos_ser_atingido_tempos_23.png" />
          <p style="font-size: 0.6em; text-align: center;">
            Foto: Tainara Torres, 2018.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51">
            <template v-slot:conteudo>
              <p>
                As trocas de alimentos, seja diretamente dos quintais e roças,
                ou quitandas feitas no forno a lenha, eram formas de estabelecer
                vínculos entre vizinhos e entre as comunidades. Dar, receber e
                retribuir uma couve, ovos, queijo, não era baseado no valor de
                mercado da commodity em questão, mas sim no fortalecimento dos
                laços sociais entre parentes e amigos. Os idosos e idosas
                passavam então essa forma de troca comunitária às novas
                gerações, ensinando aos mais novos valores de pertencimento à
                comunidade, de coesão e apoio mútuo.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="7"
          class="d-flex flex-column border__image2 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “A nossa relação aqui na comunidade era muito forte. Todo mundo aqui
            se conhecia. A gente trocava frutas, verduras, legumes... Quando uma
            pessoa ficava doente, os outros iam e ajudavam. Era um indo na casa
            do outro. Ali mesmo tomava um café, comia uma quitanda ou uma
            merenda.”
            <br />
            <strong>Bento Rodrigues, masculino, 81 anos.</strong>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb image__section">
      <v-col cols="12" class="d-flex justify-center align-center flex-column">
        <img src="../../assets/conteudos_ser_atingido_tempos_24.png" />
        <p style="font-size: 0.6em;" class="ma-0">
          Foto: Tainara Torres, 2018.
        </p>
      </v-col>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <Card borderColor="#426E51" titleColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Ao lembrarem da vida na comunidade, grande parte dos idosos
                rememora e descreve as relações afetivas e o convívio
                comunitário que partilhava. É comum observarmos nos relatos as
                falas “Todo mundo aqui se conhecia” ou “Todo mundo aqui era
                amigo” que evidenciam as relações de sociabilidade
                características da zona rural. O convívio com amigos e
                familiares que antes fazia parte do cotidiano dos idosos, deu
                lugar a uma rotina marcada pelo isolamento e ócio decorrentes
                das rupturas do cotidiano e do distanciamento social. Fato
                agravado pela topografia da cidade de Mariana com diversos
                locais com declividade acentuada que dificultam a mobilidade.
              </p>
              <p>
                Entre os relatos dos idosos e idosas, é ressaltada a memória
                afetiva que eles tinham com suas comunidades. Cumpre lembrar que
                muitos deles nasceram e criaram seus filhos nessas localidades,
                construindo diversas histórias, memórias e uma identidade
                territorial (SANTOS, 2006). O contexto urbano em que os idosos e
                idosas foram inseridos teve muitos efeitos na saúde mental e
                física, principalmente diante das limitações e perda de vínculos
                sociais e laços simbólicos.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb image__section">
      <v-col cols="12" class="d-flex justify-center align-center flex-column">
        <!-- <v-btn
          @click="showOverlayer.fig3 = !showOverlayer.fig3"
          class="d-flex d-md-none justify-center align-center"
        >
          <p style="font-size: 0.5em;" class="mb-0 cursor-pointer">
            <strong>ampliar imagem</strong>
          </p>
          <v-icon small color="#000" class="cursor-pointer">
            mdi-magnify-plus-outline
          </v-icon></v-btn
        >
        <v-overlay opacity="1" v-if="showOverlayer.fig3" class="text-center">
          <img
            max-width="20%"
            max-height="20%"
            src="../../assets/conteudos_ser_atingido_tempos_25.png"
            alt=""
          />
          <v-btn small color="#E06919" @click="showOverlayer.fig3 = false">
            fechar
          </v-btn>
        </v-overlay> -->
        <img src="../../assets/conteudos_ser_atingido_tempos_25.png" />
        <p style="font-size: 0.6em;" class="ma-0">
          Fonte: Jornal A Sirene, Ed. 16 (Julho), 2017.
        </p>
      </v-col>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <Card borderColor="#426E51" titleColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Na
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_ser_atingido_tempos' }"
                >
                  publicação do jornal A Sirene</router-link
                >, Dona Efigênia, atingida de Paracatu, relata que a dinâmica de
                se deslocar mudou drasticamente após o rompimento. Atualmente,
                assim como outros idosos e idosas atingidos, vive na cidade e
                destaca a perda da autonomia que tinha antes. Os caminhos curtos
                traçados andando, foram substituídos pelo uso de transporte
                público ou por ajuda de algum familiar. A facilidade de se
                locomover já não é mais uma realidade desses idosos e idosas
                que, por sua vez, ainda precisam lidar com a falta de
                acessibilidade e segurança do contexto urbano no qual foram
                inseridos. O mesmo problema se verifica no território, entre os
                idosos e idosas que não migraram para a sede da cidade:
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="7"
          class="d-flex flex-column border__image2 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Antes do rompimento eu tinha uma rotina. Fazia minhas atividades
            físicas, podia fazer caminhadas, coisa que não faço hoje em dia. Ali
            mesmo encontrava com as vizinhas, conversava… Mas, hoje isso ficou
            muito difícil. Principalmente com esse movimento de veículos, com a
            lama que tem nos terrenos.”
            <br /><strong>Paracatu de Baixo, feminino, 64 anos.</strong>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <Card borderColor="#426E51" titleColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Desde o rompimento, os idosos e idosas têm sido privados de
                realizar atividades de lazer que eram parte do cotidiano, como
                fazer seus exercícios físicos, ir a uma praça, participar de
                jogos, frequentar as cachoeiras e rios. Houve também uma brusca
                alteração em relação às
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'sobre_cultura_manifestacoes_tradicionais' }"
                >
                  festividades tradicionais</router-link
                >
                e culturais, visto que grande parte dos eventos, principalmente
                os religiosos, foram
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_religiosidade_calendario' }"
                >
                  adaptados ou não acontecem mais</router-link
                >.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="7"
          class="d-flex flex-column border__image2 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Antes do rompimento minha vida era boa demais. Depois do
            rompimento… não sei, perdemos a paz. Eu tinha esperança de muita
            coisa, agora, vou pensar no quê? Depois do rompimento eu acho que
            todo mundo ficou desanimado.”
            <br /><strong>Campinas, masculino, 70 anos.</strong>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <Card borderColor="#426E51" titleColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Do deslocamento forçado à interrupção das atividades cotidianas,
                o sentimento marcante entre a maioria dos idosos e idosas é a
                dúvida se, em algum dia, terão novamente o modo de vida que lhes
                foi retirado. Com o passar do tempo, alguns deles se adaptam à
                nova rotina e contexto. Entretanto, as memórias individuais e
                coletivas, os sentimentos construídos em cada comunidade, ainda
                resistem como uma defesa de algo que, de certa forma, não
                podemos deixar que seja levado pela lama; as subjetividades. Nas
                palavras de Ailton Krenak (2019, p. 15-16):
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb image__section">
      <v-col cols="12" class="d-flex justify-center align-center flex-column">
        <img src="../../assets/conteudos_ser_atingido_tempos_26.png" />
      </v-col>
    </section>

    <section class="container section__mb image__section">
      <v-col cols="12" class="d-flex justify-center align-center flex-column">
        <img src="../../assets/conteudos_ser_atingido_tempos_27.png" />
        <p style="font-size: 0.6em;">Foto: Tainara Torres, 2018.</p>
      </v-col>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card borderColor="#426E51" title="Saúde mental" titleColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Diante do exposto, pode-se observar que os efeitos do
                desastre-crime na vida dos idosos e idosas são múltiplos e
                continuados. No tópico anterior, mostramos como o rompimento
                desencadeou imensuráveis danos à saúde física da população idosa
                atingida que passou a ser cerceada a um apartamento em Mariana,
                perdendo a mobilidade, autonomia, diminuindo as atividades
                físicas, seja na lida da roça ou caminhadas e práticas ativas de
                lazer. Também a saúde mental desses idosos e idosas foi
                comprometida com o evento. Diversos relatos apontam para a
                diminuição da qualidade do sono, inibição da fome, dores de
                cabeça, sentimentos de tristeza, ansiedade e medo. Também se
                mostrou expressivo o número de relatos que aponta para o uso de
                medicações antes não necessárias, sobretudo para o controle da
                pressão arterial e antidepressivos.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <img src="../../assets/conteudos_ser_atingido_tempos_28.png" />
          <p style="font-size: 0.6em; text-align: center;">
            Foto: Lucas de Godoy / Jornal A Sirene, 2016.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <Card borderColor="#426E51" titleColor="#426E51">
            <template v-slot:conteudo>
              <p>
                O Rio Gualaxo do Norte, espaço que antes ocupava a centralidade
                no modo de vida dos atingidos, hoje contaminado pela lama tóxica
                de rejeitos, tornou-se um foco de doenças. Os problemas
                respiratórios também passaram a ser ainda mais frequentes nos
                relatos dos idosos e idosas. Outra questão constantemente
                apontada é que essas pessoas atingidas desenvolveram alguma
                doença, passaram a fazer uso de medicamentos antidepressivos,
                como clonazepam e rivotril. As condições gerais após o
                desastre-crime se materializam no aumento de doenças na mente e
                corpo dos atingidos e atingidas. Os projetos interrompidos, o
                rompimento de laços simbólicos e vínculos sociais, refletem na
                ansiedade e angústia diária, nas limitações físicas e
                psicossociais, na privação do lazer, dentre outras.
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row
        class="d-flex justify-center align-center justify-md-start align-md-start"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image mb-md-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Meu psicológico também está abalado. Tive muita insônia. Passava a
            noite acordada vendo imagens de desastres e sofrimentos. Hoje em dia
            tomo uma porção de remédios para controlar a depressão (...) dá
            vontade de morrer.”
            <br /><strong>Paracatu de Cima, feminino, 63 anos.</strong>
          </p>
        </v-col>
      </v-row>

      <v-row
        class="d-flex flex-md-row-reverse justify-center align-center justify-md-start align-md-start"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex flex-column border__image2 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Às vezes deito e acordo chorando. Não tenho entusiasmo para nada…
            nem para sair de onde estava para procurar casa eu não conseguia
            sair, as meninas é que iam. Batia o desespero, porque tinha que sair
            e não conseguia. Hoje estou mais ou menos, ninguém amola para nada.
            A tristeza está pesada ainda… tem esse tanto de reuniões e não
            resolvem nada.”
            <br /><strong>Bento Rodrigues, masculino, 65 anos.</strong>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row
        class="d-flex justify-center align-center justify-md-start align-md-start"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image mb-md-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Depois do rompimento minha saúde não está boa… cada dia é uma
            consulta diferente. Eu gosto muito de Camargos, mas hoje em dia
            quando penso naquele lugar, só me vem a lembrança triste. Foi muito
            triste o que aconteceu… quando eu estava lá ficava sonhando com
            enchentes, levantava e ia na varanda pra ver se tinha alguém
            gritando. Eu saí de lá por causa disso, se fosse antes eu não sairia
            de lá. Depois do rompimento eu passei a tomar remédio para conseguir
            dormir, senão eu não durmo.. Além disso, tem dias que fico uns dois
            dias sem comer, nem fome tenho.”
            <br /><strong>Camargos, masculino, 80 anos.</strong>
          </p>
        </v-col>
      </v-row>

      <v-row
        class="d-flex flex-md-row-reverse justify-center align-center justify-md-start align-md-start"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex flex-column border__image2 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Antes eu não tomava remédio de pressão e hoje tomo. Isso é por
            causa da preocupação. Fico pensando que tudo isso ainda vai
            acontecer de novo.”
            <br /><strong>Campinas, feminino, 61 anos.</strong>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container-fluid section__mb">
      <v-row
        class="d-flex justify-center align-center justify-md-start align-md-start"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex justify-center align-center border__image mb-md-10"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Fiquei muito preocupada depois. Aquilo ficou dias e dias na minha
            cabeça. Hoje, por exemplo, preciso tomar Rivotril.”
            <br /><strong>Paracatu de Cima, feminino, 73 anos.</strong>
          </p>
        </v-col>
      </v-row>

      <v-row
        class="d-flex flex-md-row-reverse justify-center align-center justify-md-start align-md-start"
      >
        <v-col
          cols="10"
          md="7"
          class="d-flex flex-column border__image2 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Sinto que meu quadro de hipertensão piorou depois da ida para
            Mariana. No começo apresentei quadro de depressão e passei a tomar
            mais pinga para espairecer. Acho que isso piorou muito depois que
            fui pra cidade. Lá tenho bebido mais, porque acredita que a bebida
            me ajuda a aliviar as dores físicas que tenho, alivia também o
            psicológico.”
            <br /><strong>Paracatu de Cima, masculino, 63 anos.</strong>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center"
        >
          <img src="../../assets/conteudos_ser_atingido_tempos_29.png" />
          <p style="font-size: 0.6em; text-align: center;">
            Foto: Tainara Torres, 2018.
          </p>
        </v-col>
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <Card
            borderColor="#426E51"
            title="Ainda estarei aqui ?"
            titleColor="#426E51"
          >
            <template v-slot:conteudo>
              <p>
                São quase sete anos à espera de uma
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                  >reparação justa e integral</router-link
                >
                dos danos sofridos. O
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_ser_atingido_tempos' }"
                  >tempo</router-link
                >
                passa e mantém-se a incerteza dos atingidos e atingidas quanto a
                serem devidamente reassentados, já que até a presente data
                <a
                  class="pink--text"
                  style="text-decoration: none;"
                  href="https://g1.globo.com/mg/minas-gerais/noticia/2022/06/30/mariana-mais-de-seis-anos-apos-destruicao-de-distrito-atingidos-denunciam-que-ainda-nao-tem-casas-garantidas.ghtml"
                  target="_blank"
                  >nenhuma casa foi entregue</a
                >. Além de não serem
                <router-link
                  tag="a"
                  style="text-decoration: none"
                  class="pink--text"
                  :to="{ name: 'conteudos_tematicos_luta_continua' }"
                  >reassentados</router-link
                >, os atingidos e atingidas são obrigados a ter uma existência
                aguardando a reparação, na esperança de que, de alguma forma,
                possam retomar e reconstruir a vida, enquanto isso ainda seja
                possível. São tantos anos de espera que o desânimo já aflige
                alguns idosos e idosas, e parte deles acredita que talvez nem
                esteja mais viva quando isso acontecer, como é o caso do relato
                abaixo:
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="7"
          class="d-flex flex-column border__image2 mt-5 mb-md-10 mt-md-0"
          style="z-index: 10;"
        >
          <p style="z-index: 15;" class="ma-0">
            “Já tem muitos anos que estamos à espera do novo Bento, mas não
            tenho esperança de construir minha vida de novo lá. Essa entrega do
            reassentamento está demorando muito. Tenho medo de que minhas tias,
            que são mais velhas e hoje estão debilitadas, não possam estar vivas
            aqui pra quando isso acontecer. Só de pensar isso já me deixa muito
            triste.”
            <br /><strong>Bento Rodrigues, feminino, 60 anos.</strong>
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center">
        <v-col
          cols="12"
          md="9"
          class="assessoria_card d-flex flex-column justify-center align-center"
        >
          <object
            data="/cartilha_memorias.pdf"
            type="application/pdf"
            width="100%"
            height="678"
          >
            <iframe src="/cartilha_memorias.pdf" width="100%" height="100%">
              <p>Esse navegador não suporta pdf!</p>
            </iframe>
          </object>
          <p style="font-size: 0.6em;" class="ma-0">
            Fonte: Jornal A Sirene, Ed. 37 (Abril), 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <Card borderColor="#426E51" titleColor="#426E51">
            <template v-slot:conteudo>
              <p>
                Os relatos acima retratam a realidade dos atingidos e atingidas
                que, durante esse tempo, perderam seus familiares e entes
                queridos, restando-lhes a memória e o carinho por aqueles que já
                não estão mais aqui. Conforme apresentado anteriormente, além
                das diversas mudanças ocorridas no modo de vida e cotidiano
                dessas pessoas, existem aquelas que, infelizmente, são
                imensuráveis e impossíveis de serem reparadas. Esse sentimento
                de que “não vai dar tempo”, na perspectiva de quem mais sofreu e
                vive diariamente os impactos do desastre-crime é ainda mais
                expressivo quando se fala dos idosos. Além das sucessivas
                violações de direitos, perdas e frustrações, existe a angústia e
                incerteza quanto ao futuro. A subjetividade em relação ao tempo
                reitera a sensação de finitude entre os mais velhos,
                principalmente se estarão aqui para ter suas novas casas e
                presenciarem a consolidação da justiça e reparação. Quanto ao
                futuro, há sempre um questionamento: ainda há tempo de
                recomeçar?
              </p>
              <p>
                <strong>
                  Autoras: Ana Beatriz Moreira Puchetti<sup>1</sup> , Barbara
                  Grazzielle Veloso<sup>2</sup>, Karine de Jesus
                  Santos<sup>3</sup>, Karine de Paula Pinheiro<sup>4</sup>,
                  Paula Pflüger Zanardi<sup>5</sup>
                </strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p>
              <sup>1</sup> Advogada. Assessora Jurídica na Cáritas Brasileira
              Regional Minas Gerais.
            </p>
            <p>
              <sup>2</sup> Advogada. Referência Técnica do Cadastro na Cáritas
              Brasileira Regional Minas Gerais.
            </p>
            <p>
              <sup>3</sup> Arquiteta. Assessora Técnica na Cáritas Brasileira
              Regional Minas Gerais.
            </p>
            <p>
              <sup>4</sup> Advogada. Assessora Jurídica na Cáritas Brasileira
              Regional Minas Gerais.
            </p>
            <p>
              <sup>5</sup> Cientista Social. Assessora Técnica na Cáritas
              Brasileira Regional Minas Gerais.
            </p>
            <p></p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4 mb-section">
      <v-row class="d-flex">
        <v-col cols="12">
          <div class="inside__card">
            <p><strong>Referências Bibliográficas</strong></p>
            <p>
              BEAUVOIR, Simone de. <strong>A velhice</strong>. Rio de Janeiro:
              Nova Fronteira, 1990.
            </p>
            <p>
              CLAVAL, Paul.
              <strong>O território na transição da pós-modernidade</strong>.
              GEOgraphia. Ano 1, N.2, 1999.
            </p>
            <p>
              KRENAK, Ailton. <strong>Ideias para adiar o fim do mundo</strong>.
              São Paulo: Companhia das Letras, 2019.
            </p>
            <p>
              NERI, Anita Liberalesso. Qualidade de vida na velhice. In:
              REBELATTO, José Rubens; MORELLI, José Geraldo da Silva (Orgs.).
              <strong>Fisioterapia geriátrica</strong>. São Paulo: Manole, 2003.
            </p>
            <p>
              SANTOS, Milton. O dinheiro e o território. In: SANTOS, Milton et
              al.
              <strong
                >Território, Territórios – ensaios sobre o ordenamento
                territorial</strong
              >. Rio de Janeiro: DP&A Editora, 2006.
            </p>
            <p>
              ZHOURI, Andrea. Violência, memória e novas gramáticas da
              resistência: o desastre da Samarco no Rio Doce.
              <strong>Repocs</strong>, v.16, n.32, ago./dez., p. 51-68, 2019.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__mb">
      <v-row class="d-flex justify-center align-center pa-4 section__mb">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_ser_atingido' }"
            color="#EDBD43"
          >
            <strong>Voltar para "Ser Atingido"</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb.vue";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Ser Atingido /",
          href: "conteudos_tematicos_ser_atingido",
        },

        {
          text: "Idosos",
          href: "conteudos_tematicos_ser_atingido_idosos",
        },
      ],
      showOverlayer: {
        fig1: false,
        fig2: false,
        fig3: false,
      },
    };
  },
};
</script>

<style scoped>
.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

@media (max-width: 600px) {
  p {
    font-size: 1em;
  }
  section {
    margin-top: 0;
    margin-bottom: 0;
  }

  .border__image {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }

  .border__image p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image2 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image2::before {
    display: none;
  }

  .border__image2 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image3 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image3::before {
    display: none;
  }

  .border__image3 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }

  .border__image4 {
    background-color: #f1ccad;
    padding: 0;
    border-radius: 12px;
  }
  .border__image4::before {
    display: none;
  }

  .border__image4 p {
    padding: 0 !important;
    max-width: 100% !important;
    font-size: 0.9em !important;
  }
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-4.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 0 2em 1em 2em;
}

.border__image2 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image2::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-1.png");
  background-size: 100% 100%;
}

.border__image2 p {
  font-size: 0.6em;
  max-width: 80%;
  padding: 2em 0 2em 4em;
  color: #000;
}

.border__image2 p:last-child {
  color: #000 !important;
  padding: 0 0 2em 8em;
  z-index: 20;
}

.border__image3 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image3::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-2.png");
  background-size: 100% 100%;
}

.border__image3 p {
  font-size: 0.6em;
  max-width: 85%;
  padding: 0 0 2em 12em;
  color: #000;
}

.border__image4 {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image4::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("./assets/Vector-3.png");
  background-size: 100% 100%;
}

.border__image4 p {
  font-size: 0.6em;
  max-width: 85%;
  padding: 4em 0 2em 8em;
  color: #000;
}

.border__image4 p:last-child {
  color: #000 !important;
  padding: 0 0 4em 8em;
  z-index: 20;
}

.border__image4 p:nth-child(2) {
  color: #000 !important;
  padding: 0 0 0 8em;
  z-index: 20;
}

.section__mb {
  padding-bottom: 3em;
}

.image__section img {
  background-size: cover;
}
</style>
